/* eslint-disable no-restricted-imports */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

/**
 * Hello! If you have come here, you probably have some questions about @packages/date.
 *
 * This uses Day.js under the hood, with a few extensions seen below.
 *
 * The docs for Day.js can be found at https://day.js.org/docs/en/installation/installation
 */

// Add default plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// We need to re-export these for TS reasons. Building with Bazel needs to preserve the plugin imports
// which, unless re-exported, get purged. When adding a new plugin, be sure to re-export it!
export {
  advancedFormat,
  customParseFormat,
  duration,
  isSameOrAfter,
  isSameOrBefore,
  localizedFormat,
  relativeTime,
  timezone,
  utc,
};
export default dayjs;
