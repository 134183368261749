import _ from 'underscore';

import mongoDate from '@packages/date';

/**
 * Input can be either a Date object, long timestamp, or ISO String timestamp
 */
function mongoDateInTimeZone(dateOrTimestamp, timeZoneId) {
  timeZoneId = _.isFunction(timeZoneId) ? timeZoneId() : timeZoneId;
  return mongoDate(dateOrTimestamp).tz(timeZoneId || 'Etc/UTC');
}

function timePattern(code, opts: $TSFixMe = {}) {
  code = _.isFunction(code) ? code() : code;

  let formatString = '';
  const use24Hour = code === '24-hour' || opts.force24Hour;
  const includeSeconds = opts.includeSeconds;
  const includeMillis = opts.includeMillis;
  const includeTimezone = opts.includeTimezone && !opts.timeFirst;

  formatString += use24Hour ? 'HH:mm' : 'hh:mm';
  formatString += includeSeconds ? ':ss' : '';
  formatString += includeMillis ? '.SSS' : '';
  formatString += use24Hour ? '' : ' A';
  formatString += includeTimezone ? ' z' : '';

  return formatString;
}

function separatorPattern(opts: $TSFixMe = {}) {
  return opts.useHTMLBreak ? '<br>' : ' - ';
}

function datePattern(code, opts: $TSFixMe = {}) {
  let pattern;
  code = _.isFunction(code) ? code() : code;

  switch (code) {
    case 'us1':
      pattern = opts.noYear ? 'MM/DD' : 'MM/DD/YY';
      break;
    case 'us1-long':
      pattern = opts.noYear ? 'MM/DD' : 'MM/DD/YYYY';
      break;
    case 'iso1':
      pattern = opts.noYear ? 'MM-DD' : 'YYYY-MM-DD';
      break;
    case 'aussie':
      pattern = opts.noYear ? 'DD-MM' : 'DD-MM-YY';
      break;
    case 'aussie-long':
      pattern = opts.noYear ? 'DD-MM' : 'DD-MM-YYYY';
      break;
    default:
      pattern = 'MM/DD/YY';
  }

  if (opts.prefixDayOfWeek) {
    pattern = `ddd ${pattern}`;
  }

  return pattern;
}

function format(dateOrTimestamp, dateCode, timeCode, timeZoneId, opts: $TSFixMe = {}) {
  if (dateOrTimestamp === null || dateOrTimestamp === undefined) {
    return null;
  }

  let pattern;
  if (opts.includeTime && opts.timeFirst) {
    pattern = timePattern(timeCode, opts) + separatorPattern(opts) + datePattern(dateCode, opts);
  } else {
    pattern = datePattern(dateCode, opts);
    if (opts.includeTime) {
      pattern += separatorPattern(opts) + timePattern(timeCode, opts);
    }
  }

  if (opts.inUTC) {
    return mongoDate.utc(dateOrTimestamp).format(pattern);
  }

  return mongoDateInTimeZone(dateOrTimestamp, timeZoneId).format(pattern);
}

function makeFormatter(opts) {
  return function (dateCode?: $TSFixMe, timeCode?: $TSFixMe, timeZoneId?: $TSFixMe) {
    return function (timestamp) {
      return format(timestamp, dateCode, timeCode, timeZoneId, opts);
    };
  };
}

// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
export const formatDate = makeFormatter();
export const formatDateTime = makeFormatter({ includeTime: true });
export const formatDateTimeWithSeconds = makeFormatter({ includeTime: true, includeSeconds: true });
export const formatDateTimeWithSecondsAndTimezone = makeFormatter({
  includeTime: true,
  includeSeconds: true,
  includeTimezone: true,
});

export const formatDateTimeForTimeline = makeFormatter({
  includeTime: true,
  includeSeconds: true,
  force24Hour: true,
  noYear: true,
  useHTMLBreak: true,
});

export const formatDateTimeForTag = makeFormatter({
  includeTime: true,
  includeSeconds: true,
  force24Hour: true,
  noYear: true,
  timeFirst: true,
  useHTMLBreak: true,
});

export const formatDateTimeForProfiler = makeFormatter({
  includeTime: true,
  includeSeconds: true,
  includeMillis: true,
  force24Hour: true,
  prefixDayOfWeek: true,
});

export function formatDateTimeForPerformanceAdvisor(timestamp, timeZoneId) {
  const performanceAdvisorFormat = 'ddd MMM DD YYYY h:mma';
  return mongoDateInTimeZone(timestamp, timeZoneId).format(performanceAdvisorFormat);
}

export function formatDateTimeForRealtime(timestamp, timeZoneId, { withSpaces = true } = {}) {
  const realtimeFormat = withSpaces ? 'HH : mm : ss' : 'HH:mm:ss';
  return mongoDateInTimeZone(timestamp, timeZoneId).format(realtimeFormat);
}

export function formatDateTimeFromSeconds(dateCode, timeCode, timeZoneId) {
  return function (timestampInSeconds) {
    return format(timestampInSeconds * 1000, dateCode, timeCode, timeZoneId, { includeTime: true });
  };
}

export function formatDateTimeWithTimezoneNoSeconds(timestamp, timeZoneId) {
  const dateTimeFormat = `HH:mm z`;
  return mongoDateInTimeZone(timestamp, timeZoneId).format(dateTimeFormat);
}

export function formatDateTimeWithTimezoneWithDate(timestamp, timeZoneId) {
  const dateTimeFormat = `hh:mm A - MM/DD/YY z`;
  return mongoDateInTimeZone(timestamp, timeZoneId).format(dateTimeFormat);
}
